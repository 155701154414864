<header class="bg-green-300 py-3">
  <div
    class="container mx-auto w-full h-auto md:h-24 flex flex-wrap md:flex-no-wrap items-center justify-between"
  >
    <div class="brand-wrap">
      <h2 class="text-2xl font-bold text-black">
        <a routerLink="/">Jide Lambo</a>
      </h2>
    </div>

    <div class="header-menu-wrap mt-6 md:mt-0 overflow-y-scroll">
      <ul class="menu-group md:text-xl">
        <li class="mr-4 md:mr-6">
          <a
            routerLink="/"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="hover:underline"
            >Work</a
          >
        </li>
        <li class="mr-4 md:mr-6">
          <a routerLink="/about" routerLinkActive="active" class="hover:underline">About</a>
        </li>
        <li class="mr-4 md:mr-6">
          <a routerLink="/playground" routerLinkActive="active" class="hover:underline">Playground</a>
        </li>
        <!-- <li class="mr-4 md:mr-6">
          <a routerLink="/design-process" routerLinkActive="active">Process</a>
        </li> -->
      </ul>
    </div>
    <!-- </div> -->
  </div>
</header>
