import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-design-process',
  templateUrl: './design-process.component.html',
  styleUrls: ['./design-process.component.scss']
})
export class DesignProcessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
