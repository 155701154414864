<div>
  <app-home-briefs></app-home-briefs>

  <app-recent-work></app-recent-work>

  <section class="container mb-12">
    <h3 class="text-2xl font-bold mb-4">More of my design cases:</h3>
    <ul>
      <li>
        <a
          href="https://www.notion.so/jide/Improve-GIGM-com-checkout-experience-466084e6e6ac4b028b7885df029129ef"
          target="_blank"
          class="view-project hover:underline"
        >
        Improving GIGM.com checkout experience for customers</a>
      </li>
    </ul>
  </section>
</div>
<div class="know-me-wrap">
  <div class="know-me">
    <a routerLink="/playground" class="hover:underline">Check playground for my random work</a>
  </div>
</div>
<app-footer-social></app-footer-social>
