<div class="container">

    <section class="boxed">
      <div class="row-message">
        <h1>
          404
        </h1>
        <h2>
          I couldn't find this page.
        </h2>
      </div>
  
  
      <div class="header-menu-wrap">
        <div class="info">
          <p>Maybe it's out there, somewhere.</p>
          <p>You can always find useful things on the <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">homepage</a></p>
        </div>
      </div>
  
    </section>
  </div>