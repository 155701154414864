<div class="container mx-auto py-10 md:py-16">
  <article class="max-w-4xl project-desc">
    <div class="desc-more mb-8">
      <h2 class="text-2xl font-semibold leading-10">My Design Process 📇</h2>
    </div>

    <div class="desc-more">
      <h2 class="text-xl font-semibold">Design Research</h2>
      <p class="text-lg leading-10">
        I identify my users and try to find their needs. I also try to learn of
        the context of their problem. It gives me an insight into the problems
        we're trying to solve.
      </p>
    </div>

    <div class="desc-more">
      <h2 class="text-xl font-semibold">Generate Ideas</h2>
      <p class="text-lg leading-10">
        After identifying these problems, I come up with ideas by observing,
        interviewing, tracking and surveys.
      </p>
    </div>

    <div class="desc-more">
      <h2 class="text-xl font-semibold">Define all ideas</h2>
      <p class="text-lg leading-10">
        In this phase, I make sense of all the ideas collected by using the
        wireframes or low-fi designs. This helps the team make decisions on
        which idea solves our user's problems.
      </p>
    </div>

    <div class="desc-more">
      <h2 class="text-xl font-semibold">Prototyping</h2>
      <p class="text-lg leading-10">
        Prototypes are designed and I can demonstrate the idea to colleagues,
        stackholders and a user-group. This phase helps me answer specific
        questions, and get feedback from people. By testing with users, it helps
        me gain much insight into what works, and what doesn’t work, and I can
        iterate the design.
      </p>
    </div>

    <div class="desc-more">
      <h2 class="text-xl font-semibold">Design Development</h2>
      <p class="text-lg leading-10">
        I can implement prototype (UX) designs as UI features, components and functionalities. 
        I can also plan and design implementation for multilingual frontend solution. 
        Developing and maintaining good coding practices for better web accessibility is also my focus and interest.
      </p>
    </div>
  </article>
</div>
<div class="know-me-wrap">
  <div class="know-me">
    <a routerLink="/about">Know more about me</a>
  </div>
</div>
<app-footer-social></app-footer-social>
