<div class="container mx-auto">
  <div class="work-page-title">
    <h3>Case Study: BCaster</h3>
  </div>
</div>
<!-- <app-works-hero></app-works-hero> -->
<div class="work-hero-wrap" *ngFor="let hero of heros">
  <div class="hero-bckgrnd" style="background-color: #ee8933">
    <img
      src="../../../assets/img/hero/{{ hero.mock }}"
      alt="{{ hero.mock }}"
      loading="lazy"
    />
    <img
      src="../../../assets/img/hero/{{ hero.mockSm }}"
      alt="{{ hero.mockSm }}"
      loading="lazy"
    />
  </div>
</div>
<div class="container mx-auto">
  <article class="project-desc">
    <div class="desc-more">
      <h2 class="desc-title">About BCaster</h2>
      <div class="text-lg leading-10">
        <p>
          BCaster provides tools for capturing media content (photos and videos)
          using her Web App camera. The content can then be uploaded to the
          dashboard, where it is automatically analyzed and tagged with AI. The
          media content is processed for easy search based on time, location,
          context, and media quality.
        </p>
      </div>
    </div>

    <div class="desc-more">
      <h2 class="text-xl font-semibold">Challenge</h2>
      <div class="text-lg leading-10">
        <p>
          How do you design a product that there's nothing like it in the
          market? As a typical user of a product; say, for instance, a Tesla
          Model 3 electric car. I can take photos or videos of myself driving my
          Tesla and post these media content on one or many of the social media
          apps. As a Tesla marketing manager, how can I curate these media
          content from our customers; especially when this content is mostly
          lost in the sea of posts on Facebook, Twitter, or Instagram.
        </p>
        <p>
          How do we design tools to help business owners get direct media
          content from their customers (of course, with consent), reward them
          for sharing, and use this content for advert and other business
          purposes.
        </p>
      </div>
    </div>

    <div class="desc-more">
      <h2 class="text-xl font-semibold">Solution</h2>
      <div class="text-lg leading-10">
        <p>
          My task was to understand the business goals, our client, and how the
          existing MVP works. As the design lead, I collaborated with the
          project managers, and engineers to highlight features to help our
          users.
        </p>
        <p>
          In identifying what our users needs, we built a BCaster Web App camera
          that can be easily integrated on the business owner's website and then
          used to take photos/videos. Furthermore, we built a dashboard tool
          where business owners can easily collect their customer's media
          content (with consent). This content will then be automatically
          analyzed with our AI algorithm and can be processed for easy search
          based on time, location, context, and media quality.
        </p>
        <p>
          I learned how best to present photos and videos on the web platform
          for our users. I also learned how best to design usable search
          features and data visualization.
        </p>
      </div>
    </div>

    <div>
      <div class="flex justify-between flex-wrap mb-2">
        <div class="p-2 w-full md:w-1/3">
          <img
            src="../../../assets/img/recent-works/bc/one-min.png"
            alt="Web app"
            loading="lazy"
          />
        </div>
        <div class="p-2 w-full md:w-1/3">
          <img
            src="../../../assets/img/recent-works/bc/two-min.png"
            alt="Web app"
            loading="lazy"
          />
        </div>
        <div class="p-2 w-full md:w-1/3">
          <img
            src="../../../assets/img/recent-works/bc/three-min.png"
            alt="Web app"
            loading="lazy"
          />
        </div>
      </div>
      <div class="flex justify-between flex-wrap">
        <div class="p-2 w-full md:w-1/2">
          <img
            src="../../../assets/img/recent-works/bc/dashboard-card-min.png"
            alt="Web app"
            loading="lazy"
            class="shadow-lg"
          />
        </div>
        <div class="p-2 w-full md:w-1/2">
          <img
            src="../../../assets/img/recent-works/bc/dashboard-list-min.png"
            alt="Web app"
            loading="lazy"
            class="shadow-lg"
          />
        </div>
        <div class="p-2 w-full md:w-1/2">
          <img
            src="../../../assets/img/recent-works/bc/dashboard-advanced-filter-min.png"
            alt="Web app"
            loading="lazy"
            class="shadow-lg"
          />
        </div>
        <div class="p-2 w-full md:w-1/2">
          <img
            src="../../../assets/img/recent-works/bc/dashboard-media-modal-min.png"
            alt="Web app"
            loading="lazy"
            class="shadow-lg"
          />
        </div>
      </div>
    </div>
  </article>
</div>
<div class="know-me-wrap">
  <div class="know-me">
    <a routerLink="/about">Know more about me</a>
  </div>
</div>
<app-footer-social></app-footer-social>
