<div class="container mx-auto">
  <div class="work-page-title">
    <h3>Case Study: GIGM.com</h3>
  </div>
</div>
<!-- <app-works-hero></app-works-hero> -->
<div class="work-hero-wrap" *ngFor="let hero of heros">
  <div class="hero-bckgrnd" style="background-color: rgb(250, 211, 224)">
    <img src="../../../assets/img/hero/{{ hero.mock }}" alt="" loading="lazy" />
    <img
      src="../../../assets/img/hero/{{ hero.mockSm }}"
      alt=""
      loading="lazy"
    />
  </div>
</div>
<div class="container mx-auto">
  <article class="project-desc">
    <div class="desc-more">
      <h2 class="desc-title">Why this project? Travelling had to be easy</h2>
      <p class="text-lg leading-10">
        We built the GIGM.com with the intention to change the transport system
        across Africa with an approach to give users the opportunity to book,
        review and reroute trips from their mobile phones and pc. We needed to
        make bus fare bookings easy for our guests and ease them the stress of
        queuing up at the bus terminals for fare tickets.
      </p>
    </div>

    <div class="desc-more">
      <h2 class="text-xl font-semibold">Goal</h2>
      <ul class="list-disc">
        <li>
          <p class="text-lg leading-10">
            Fare options: additional fare discount for booking online and even
            more fare discount for signing up on the platform.
          </p>
        </li>
        <li>
          <p class="text-lg leading-10">
            Easy-to-understand filter search results: Guests can easily sort
            search results to find the best travel deals.
          </p>
        </li>
        <li>
          <p class="text-lg leading-10">
            Trip details: Guests know what to expect during their journey, with
            information about departure time and Vehicle type.
          </p>
        </li>
        <li>
          <p class="text-lg leading-10">
            Sign in option: Guests can keep tabs on their travel history while
            signed in.
          </p>
        </li>
        <li>
          <p class="text-lg leading-10">
            Easy-to-use payment platforms: Our goal is for Guests to not only
            book a trip within 2 minutes, but to pay online stress-free.
          </p>
        </li>
      </ul>
    </div>

    <div class="desc-more">
      <h2 class="text-xl font-semibold">My Contribution</h2>
      <ul>
        <li>
          <p class="text-lg leading-10">
            GIGM.com has a huge user base, so it made sense for us to start our
            user testing with existing customers. We had interviews with a few
            of our exisiting users. It was easy to approach them at the bus
            terminals across the city. I asked about their experience while
            making booking reservations. I also learned about their likes and
            dislikes while using the service, their pain-points, the means by
            which they make their booking reservations, and other alternate
            services.
          </p>
        </li>
        <li>
          <p class="text-lg leading-10">
            I continued working with the project team by coming up with a whole
            new set of ideas to solve our user’s needs, wants and desires. Now,
            most of them were impratical, but we come up with a lot of high
            quality ideas.
          </p>
        </li>
        <li>
          <p class="text-lg leading-10">
            Using the information gathered from interviews, I gathered all the
            common user needs, and wants, and used all this information to form
            personas. Journey maps were also created.
          </p>
        </li>
        <li>
          <p class="text-lg leading-10">
            Prototyping was the most interesting part because we came up with
            many inte ideas. I designed walk-through prototypes that allowed
            people to go through one activity to another. I tested the prototype
            with users by asking them to perform simple functions. Iterations
            were made from the feedback gotten from usability tests. I also
            developed the web user interface.
          </p>
        </li>
      </ul>
    </div>

    <div>
      <div class="flex justify-between flex-wrap mb-2">
        <div class="p-2 w-full md:w-1/3">
          <img
            src="../../../assets/img/recent-works/gigm/mobile-home-min.png"
            alt="gigm mobile web"
            loading="lazy"
          />
        </div>
        <div class="p-2 w-full md:w-1/3">
          <img
            src="../../../assets/img/recent-works/gigm/mobile-choose-bus-min.png"
            alt="Web app"
            loading="lazy"
          />
        </div>
        <div class="p-2 w-full md:w-1/3">
          <img
            src="../../../assets/img/recent-works/gigm/mobile-passenger-details-min.png"
            alt="Web app"
            loading="lazy"
          />
        </div>
      </div>
      <div class="flex justify-between flex-wrap">
        <div class="p-2 w-full md:w-1/2">
          <img
            src="../../../assets/img/recent-works/gigm/web-home-min.png"
            alt="Web app"
            loading="lazy"
            class="shadow-lg"
          />
        </div>
        <div class="p-2 w-full md:w-1/2">
          <img
            src="../../../assets/img/recent-works/gigm/web-choose-bus-min.png"
            alt="Web app"
            loading="lazy"
            class="shadow-lg"
          />
        </div>
        <div class="p-2 w-full md:w-1/2">
          <img
            src="../../../assets/img/recent-works/gigm/web-passenger-details-min.png"
            alt="Web app"
            loading="lazy"
            class="shadow-lg"
          />
        </div>
      </div>
    </div>
  </article>
</div>
<div class="know-me-wrap">
  <div class="know-me">
    <a routerLink="/about">Know more about me</a>
  </div>
</div>
<app-footer-social></app-footer-social>
