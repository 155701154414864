<footer class="footer py-5 md:py-10">
  <div class="container max-w-6xl">
    <div class="socials-wrap">
      <ul class="social-icons-wrap">
        <li *ngFor="let social of socials">
            <a href="{{ social.url }}" target="_blank">{{ social.name }}</a>
        </li>
      </ul>
    </div>
  </div>
</footer>