<div class="container py-10 md:py-16">
  <div class="desc-more mb-8">
    <h2 class="text-2xl font-medium leading-10 mb-6">playground 📇</h2>
    <p class="text-lg leading-10">
      This is my space where I share random work, unfinished projects, and
      articles. I get to share things I learn without being vulnerable.
    </p>
  </div>

  <div class="gridWrapper">
    <div class="wrapper shadow">
      <a
        href="https://jidelambo.medium.com/why-you-need-design-engineers-on-your-team-81378ec36e4d"
        class="grid-item-block flex flex-col justify-end h-full"
        style="
          background-image: url(../../../assets/img/playground/workstation-design-engineer.jpeg);
        "
        target="_blank"
        rel="noopener noreferrer"
      >
        <div class="z-10 p-6 bg-black bg-opacity-75">
          <h3 class="text-white font-bold capitalize">
            Why you need design engineers on your team.
          </h3>
          <p class="text-white">Reference: jidelambo.medium.com</p>
        </div>
      </a>
    </div>
    <div class="wrapper shadow">
      <a
        href="https://jidelambo.medium.com/why-semantics-are-important-658a4a24248e"
        class="grid-item-block flex flex-col justify-end h-full"
        style="
          background-image: url(../../../assets/img/playground/semantics.png);
        "
        target="_blank"
        rel="noopener noreferrer"
      >
        <div class="z-10 p-6 bg-black bg-opacity-75">
          <h3 class="text-white font-bold capitalize">
            Why semantics are important.
          </h3>
          <p class="text-white">Reference: jidelambo.medium.com</p>
        </div>
      </a>
    </div>
    <div class="wrapper shadow">
      <a
        href="https://css-tricks.com/new-in-chrome-css-overview/"
        class="grid-item-block flex flex-col justify-end h-full"
        style="
          background-image: url(../../../assets/img/playground/css-overview-tailwindcss.png);
        "
        target="_blank"
        rel="noopener noreferrer"
      >
        <div class="z-10 p-6 bg-black bg-opacity-75">
          <h3 class="text-white font-bold capitalize">
            The new CSS overview feature on Chrome
          </h3>
          <p class="text-white">Reference: css-tricks</p>
        </div>
      </a>
    </div>
    <div class="wrapper shadow">
      <a
        href="https://www.youtube.com/watch?v=OH0-Ky4oMpU"
        class="grid-item-block flex flex-col justify-end h-full"
        style="
          background-image: url(../../../assets/img/playground/stream-highlight.png);
        "
        target="_blank"
        rel="noopener noreferrer"
      >
        <div class="z-10 p-6 bg-black bg-opacity-75">
          <h3 class="text-white font-bold capitalize">
            An highlight of live stream I made for setting up CSS grid with
            Tailwind
          </h3>
          <p class="text-white">Reference: youtube.com/jidelambo</p>
        </div>
      </a>
    </div>
    <div class="wrapper shadow">
      <a
        href="https://codeburst.io/understanding-css-flexbox-d6162885fefe"
        class="grid-item-block flex flex-col justify-end h-full"
        style="
          background-image: url(../../../assets/img/playground/flexbox-css.png);
        "
        target="_blank"
        rel="noopener noreferrer"
      >
        <div class="z-10 p-6 bg-black bg-opacity-75">
          <h3 class="text-white font-bold capitalize">
            Understanding CSS Flexbox
          </h3>
          <p class="text-white">Reference: jidelambo.medium.com</p>
        </div>
      </a>
    </div>
    <div class="wrapper shadow">
      <a
        href="https://jidelambo.medium.com/the-relationship-between-designers-and-engineers-4fd657515b27"
        class="grid-item-block flex flex-col justify-end h-full"
        style="
          background-image: url(../../../assets/img/playground/relationship-design-engineer.jpeg);
        "
        target="_blank"
        rel="noopener noreferrer"
      >
        <div class="z-10 p-6 bg-black bg-opacity-75">
          <h3 class="text-white font-bold capitalize">
            The relationship between designers and engineers.
          </h3>
          <p class="text-white">Reference: jidelambo.medium.com</p>
        </div>
      </a>
    </div>
  </div>
</div>
<div class="know-me-wrap">
  <div class="know-me">
    <a routerLink="/design-process">Read about my design process</a>
  </div>
</div>
<app-footer-social></app-footer-social>
