<div class="container mx-auto">
  <div class="work-page-title">
    <h3>Case Study: Coupon Cooler</h3>
  </div>
</div>
<!-- <app-works-hero></app-works-hero> -->
<div class="work-hero-wrap" *ngFor="let hero of heros">
  <div class="hero-bckgrnd" style="background-color: #fdf182">
    <img src="../../../assets/img/hero/{{ hero.mock }}" alt="" loading="lazy" />
    <img
      src="../../../assets/img/hero/{{ hero.mockSm }}"
      alt=""
      loading="lazy"
    />
  </div>
</div>
<div class="container mx-auto">
  <article class="project-desc">
    <div class="desc-more">
      <h2 class="desc-title">
        Problem to be solved. Make shopping online with coupon deals very easy
        and interesting
      </h2>
      <p class="text-lg leading-10">
        The couponcooler.com on mobile experience is to address shoppers who
        search for interesting deals, and discounts. The business wanted a
        one-stop shop for shoppers.
      </p>
      <p class="text-lg leading-10">
        I worked with 5-man team (4 developers, 1 project manager). My role was
        to detect what problems exists, and propose a solution at the usability
        and interface level for web mobile only.
      </p>
    </div>

    <div class="desc-more">
      <h2 class="text-xl font-semibold">Project Approach</h2>
      <ul class="list-disc">
        <li>
          <p class="text-lg leading-10">
            I asked my client a few questions like “How are the benefits of
            using coupons? What information is relevant for their shopping
            experience? And many other relevant questions...”
          </p>
        </li>
        <li>
          <p class="text-lg leading-10">
            I interviewed shoppers and workers of a few vendors site. I was able
            to have a deeper understanding of their needs. The shoppers needed
            to do quick search for items. Shoppers also needed to get first-hand
            information about available discounted deals. Shoppers wanted
            convenience while shopping. Defining the problems helped me gather
            ideas for features and other elements that will allow us solve the
            problems.
          </p>
        </li>
        <li>
          <p class="text-lg leading-10">
            With a reasonable amount of knowledge gathered, we brainstromed
            ideas like having menus categorized by Deals, Brands and Coupons. We
            also came up with ideas to filter available deals by how Recent and
            also how soon they’ll be Expiring. Deals should have brief
            description and discocunt value.
          </p>
        </li>
        <li>
          <p class="text-lg leading-10">
            I designed Lo-Fi designs with these specific features and tested it
            within the business team and a small group of users. This experiment
            helped us to identify the possible solution for the problems
            identified earlier. At the end, we were able to have a better idea
            of how our real users would behave when interacting with the
            product.
          </p>
        </li>
        <li>
          <p class="text-lg leading-10">
            Having understood how the users behaved with the Lo-Fi prototype, I
            designed a Hi-Fi prototype and we tested it again on our users. we
            had great feedback and continued iterating for best user experience.
            I coded the mockups using HTML, CSS and Javascript while all
            considering mobile responsiveness.
          </p>
        </li>
      </ul>
    </div>
    <div class="mb-4">
      <div class="flex justify-between flex-wrap mb-2">
        <div class="p-2 w-full md:w-1/3">
          <img
            src="../../../assets/img/recent-works/couponcooler/home.png"
            alt="couponcooler mobile web"
            loading="lazy"
          />
        </div>
        <div class="p-2 w-full md:w-1/3">
          <img
            src="../../../assets/img/recent-works/couponcooler/recent-deals.png"
            alt="couponcooler"
            loading="lazy"
          />
        </div>
        <div class="p-2 w-full md:w-1/3">
          <img
            src="../../../assets/img/recent-works/couponcooler/item-deal.png"
            alt="couponcooler"
            loading="lazy"
          />
        </div>
        <div class="p-2 w-full md:w-1/3">
          <img
            src="../../../assets/img/recent-works/couponcooler/about-deal.png"
            alt="couponcooler"
            loading="lazy"
          />
        </div>
      </div>
    </div>
    <div class="desc-more">
      <h2 class="text-xl font-semibold">Relevant tools used</h2>
      <ul class="list-disc">
        <li>
          <p class="text-lg leading-10">
            Gather user’s needs via Interviews and Questionnaire
          </p>
        </li>
        <li>
          <p class="text-lg leading-10">
            User personas to identify our key stakeholder to design for.
            Card-sorting to help pin-point the common needs of our users.
          </p>
        </li>
        <li>
          <p class="text-lg leading-10">
            Design prototypes with sketch. Test prototypes using Invision.
          </p>
        </li>
        <li>
          <p class="text-lg leading-10">
            Code prototype in HTML, CSS, and Javascript.
          </p>
        </li>
      </ul>
    </div>
  </article>
</div>
<div class="know-me-wrap">
  <div class="know-me">
    <a routerLink="/about">Know more about me</a>
  </div>
</div>
<app-footer-social></app-footer-social>
