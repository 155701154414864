<div class="container py-4 md:py-20">
  <section class="about-wrap max-w-4xl">
    <h2 class="text-2xl font-medium leading-10 mb-6">Hi again, 🙂 🤟</h2>

    <img
      class="float-right ml-4 my-2 h-64 w-full md:w-64 object-cover border shadow-md"
      src="../../../assets/img/jidelambo.JPG"
      loading="lazy"
      alt="Jide Lambo"
    />

    <article class="text-lg leading-10">
      <p class="mb-6">
        I’m a Helsinki-based product designer with Front-end development skills.
        I've had the opportunity to design products in the space of digital
        media agency, Saas tech, fintech (banking), road transportation,
        AI-driven platform, and cloud-based tech products.
      </p>
      <p class="mb-6">
        My journey started as a self-taught designer while working at Crawford
        University, Nigeria in 2012, then I joined Deefrent - A digital agency
        in 2015 where I worked on clients projects ranging from e-commerce
        websites to portfolio websites, to Government web portals. Later in
        2016, I joined GIGM.com - the biggest road transportation company in
        Nigeria, and led the design team in designing and developing the
        customer's booking platform both on mobile and web. I also focused on
        designing usable web experience for in-house tools.
      </p>
      <p class="mb-6">
        After my time at GIGM.com, I joined Guaranty Trust Bank, where my main
        focus was building the customer's mobile banking app called GTWorld. I
        also worked with the team that designed a well documented API so Fintech
        companies can build custom payments experiences.
      </p>
      <p class="mb-6">
        In late 2018, I joined BCaster.com - a Finland startup that automates
        user generating contents for businesses. I led the design team where I
        helped guide the user experience for both the customers and in-house
        products. As a startup team, I took up designing and developing user
        interfaces and experiences for the customer's dashboard, and Web-apps.
      </p>
      <p class="mb-6">
        In 2020, I joined the Intergalactico design team with Nordcloud. As a
        cloud-first company, my role has been focused on designing user
        experiences for cloud-based applications.
      </p>
      <p class="mb-6">
        My work gives me vast experience in design reviews, product design, web
        accessibility, front-end web development, user research, and usability
        testing.
      </p>
      <p>
        Outside work, I enjoy taking city photographs, bike riding for long
        miles, and horseback riding.
      </p>
    </article>
  </section>

  <section class="about-wrap mt-20">
    <h2 class="text-2xl font-bold mb-4">Relevant Skills &amp; Tools 🔧</h2>

    <div class="skills-wrap">
      <ul class="skills-list list-disc pl-4">
        <li class="text-lg leading-10" *ngFor="let skill of skills">
          {{ skill.name }}
        </li>
      </ul>
    </div>
  </section>
</div>
<div class="know-me-wrap">
  <div class="know-me">
    <a routerLink="/design-process">Read about my design process</a>
  </div>
</div>
<app-footer-social></app-footer-social>
