<section class="py-10 md:py-16 bg-green-300">
  <div class="container">
    <div class="max-w-6xl brief-wrap">
      <h2 class="text-2xl font-medium text-black leading-10 mb-6">
        👋🏾 Hi, my name is Jide (pronounced: Ji-Day), and I am a UX Designer. I specialize in the discipline of bridging the gap between designers and engineers. 
        I am passionate about solving both customer and business problems.
      </h2>
      <article class="text-lg text-black leading-10">
        <p>I have worked on projects in a variety of areas such as mobility transportation, banking, AI- driven tech, and cloud-based platforms.</p>
        <p>
          This is the link to my
          <a
            href="https://www.youtube.com/jidelambo"
            target="_blank"
            class="text-black font-semibold underline hover:text-purple-600"
            >youtube channel</a
          >
          where I stream about design, development and generally living in
          Finland.
          You can also read about my 
          <a
            routerLink="/design-process"
            class="text-black font-semibold underline hover:text-purple-600"
            >design and dev process.</a
          >
        </p>

        <p>
          You can check my work progress on
          <a
            href="https://www.linkedin.com/in/jidelambo/"
            target="_blank"
            class="text-black font-semibold underline hover:text-purple-600"
            >Linkedin here</a
          >
          and also read my articles
          <a
            href="https://medium.com/@jidelambo"
            target="_blank"
            class="text-black font-semibold underline hover:text-purple-600"
            >on medium</a
          >
          where I write about design, and web development.
        </p>
        <p>I also designed, and developed this portfolio. You can find the source code in this <a href="https://github.com/JideLambo/portfolio" target="_blank" class="text-black font-semibold underline hover:text-purple-600">github repo</a>.</p>
      </article>
    </div>
  </div>
</section>
